.accounts-screen{
    height: 768px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h1{
        margin: 30px 0;
    }
    .accounts-form{
        height: 100%;
        width: 992px;
        display: flex;
        flex-direction: column;
        background: $secure-grey;
        box-sizing: border-box;
        padding: 15px;
        border: black solid 1px;
        margin-bottom: 30px;
        overflow-y: scroll;
        @media(max-width: $l-screen){
            width: 360px;
        }
    }

    .account{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        border-bottom: #B3B3B3 solid 1px;
        padding: 10px 0;
        @media(max-width: $l-screen){
            grid-template-columns: 4fr;
        }
        p {
            margin: 0;
        }
        .account-email{
            font-size: $m-font-size;
        }
        .checkbox-container{
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width: $l-screen){
                justify-content: flex-start;
            }
            label{
                cursor: pointer;
            }
            input[type=checkbox]{
                margin-left: 7px;
                width: 18px;
                height: 18px;
            }
        }
        .account-date{
            margin-left: 5px;
            font-weight: 700;
        }
        &.to-delete{
            color: rgba(0, 0, 0, .3);
        }
    }
}

.no-access{
    margin: 80px 30px;
    text-align: center;
}
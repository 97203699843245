@mixin fade-in-animation($duration:.5s){
  @keyframes fade-in{
    from{opacity: 0;}
    to{opacity: 1;}
  }
  animation: fade-in $duration ease-in-out
}

@mixin spin-animation(){
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  animation: spin 1s linear infinite
}
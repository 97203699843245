$primary-font: 'Reem Kufi', sans-serif;

$s-font-size: 18px;
$m-font-size: 24px;
$l-font-size: 36px;
$xl-font-size: 52px;
$xxl-font-size: 67px;

$gold: #E1C997;
$goldish: rgba(225, 201, 151, .7);
$secure-grey: #F0F0F0;

$xs-screen: 412px;
$s-screen: 576px;
$m-screen: 768px;
$l-screen: 992px;
$xl-screen: 1280px;

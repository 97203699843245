
.about-screen{
  @include fade-in-animation;
  padding: 0 120px;
  height: 1500px;
  position: relative;
  @media(max-width: $l-screen){
    padding: 0;
    height: auto;
  }

  .about-floater{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: calc(25%/4); //  a quarter of the background position
    box-sizing: border-box;
    height: 83%;
    width: 83%; // (1196px/1440px)
    margin: 0;
    z-index: 1;

    @media(max-width: $l-screen){
      width: 100%;
      height: auto;
      position: relative;
      top: initial;
      z-index: 0;
    }

    .about-me{
      display: flex;
      justify-content: space-evenly;

      @media(max-width: $l-screen){
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 1106px;
      }
      @media(max-width: $xs-screen){
        height: 1000px;
      }

      img{
        height: 545px;
        border: 2px solid black;
        @media(max-width: $l-screen){
          width: 410px;
          border: none;
        }
        @media(max-width: $s-screen){
          width: 350px;
          height: 485px;
        }
      }
      p{
        height: 545px;
        box-sizing: border-box;
        width: 332px;
        padding: 30px 15px;
        font-size: $m-font-size;
        line-height: 40px;
        background-color: white;
        border: 2px black solid;
        word-spacing: 10px;
        @media(max-width: $l-screen){
          border: none;
          width: 100%;
          height: auto;
          padding: 30px;
        }
        @media(max-width: $s-screen){
          font-size: $s-font-size;
        }
        @media(max-width: $xs-screen){
          padding: 15px;
        }
      }
    }

    .about-jamix{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 677px;
      @media(max-width: $l-screen){
        height: auto;
      }
      h1{
        @include fade-in-animation(2s);
        font-size: $xl-font-size;
        letter-spacing: 2.5px;
        @media(max-width: $s-screen){
          font-size: $l-font-size;
        }
      }

      img{
        height: 324px;
        @media(max-width: $s-screen){
          width: 350px;
          height: 246px;
        }
      }

      .about-jamix-ps{
        box-sizing: border-box;
        height: 233px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 30px;
        @media(max-width: $l-screen){
          height: auto;
        }
        @media(max-width: $xs-screen){
          padding: 15px;
        }

        p{
          line-height: 40px;
          font-size: $m-font-size;
          word-spacing: 10px;
          @media(max-width: $s-screen){
            font-size: $s-font-size;
          }
        }
        
      }
    }
  }
    
  .about-gold-bg{
    @include fade-in-animation(2s);
    position: relative;
    top: 25%;
    background-color: $goldish;
    height: 635px;
    width: 100%;
    @media(max-width: $l-screen){
      display: none;
    }
  }
}

.corp{
  font-weight: 900;
  color: $gold;
  font-style: italic
}

.menu-modal{
  @include fade-in-animation;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: white;

  img{
    width: 56px;
    align-self: flex-end;
    margin: 30px;
  }
  ul{
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    li{
      font-size: $l-font-size;
    }
  }
}
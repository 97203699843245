.contact-screen{
  @include fade-in-animation;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  img{
    width: 56px
  }
}
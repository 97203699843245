footer{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $m-font-size;
  letter-spacing: 1.75px;

  @media (max-width: 576px) {
    font-size: $s-font-size;
    letter-spacing: 1px;
  }
}
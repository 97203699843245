.hamburger-menu-toggler{
  cursor: pointer;
  display: none;
  @media(max-width: $m-screen){
    display: block;
  }
  .hamburger-menu-bar{
    border-bottom: 4px solid black;
    width: 35px;
    margin-bottom: 6px;
    transition: all .25s;
  }
}

.mobile-menu-user-btn{
  cursor: pointer;
  display: none;
  @media(max-width: $m-screen){
    display: flex;
    align-items: center;
    border: black solid 2px;
  }
  span{
    margin-right: 8px;
  }
  .down-arrow{
    border-left: solid black 2px;
    border-bottom: solid black 2px;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    position: relative;
    top: -1px;
  }
}
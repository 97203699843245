@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;700&display=swap');

@import './variables';
@import './animations';
@import './components/gallery-carousel', './components/buttons', './components/hamburger_menu', './components/menu_modal';
@import './layout/layout', './layout/header', './layout/footer';
@import './screens/home', './screens/about', './screens/contact', './screens/services', './screens/gallery', './screens/auth', './screens/accounts';

body{
  font-family: $primary-font;
}

a{
  text-decoration: none;
  color: black;
}

.restricted-screen{
  border: 4px solid $goldish;
  box-shadow:inset 0 0 10px 2px $gold;
  &:not(.about-screen){
    padding: 15px
  }
}
.admin-info{
  color: red;
  margin: 0;
  font-size: 18px;
}
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media (max-width: $m-screen) {
    flex-direction: row;
  }
  @media (max-width: $s-screen) {
    justify-content: space-between;
    margin: 0 5px; 
  }

  img {
    width: 258px;
    height: 168px;
    grid-column: 2;
    @media (max-width: $m-screen) {
      width: 174px;
      height: 117px;
      grid-column: 1;
    }
  }
  .admin-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .admin-greeting{
    display: flex;
    grid-column: 3;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    p{
      text-transform: uppercase;
    }
    a{
      font-size: 18px;
      text-decoration: underline;
      color: blue;
      transition: all .25s ease-in;
      padding: 0 5px;
      &:hover{
        background-color: rgba(33, 162, 243, 0.472);
        border-radius: 3px;
        color: black;
      }
    }
    span{
      margin-right: 5px;
    }
    @media (max-width: $m-screen) {
      display: none;
    }

  }
  nav {
    display: flex;
    justify-content: space-evenly;
    font-size: $m-font-size;
    width: 100%;
    @media (max-width: $m-screen) {
      display: none;
    }
    a {
      transition: all 0.15s ease-in-out;
      &:visited {
        outline: solid 1px white;
      }
      &:hover {
        color: $gold;
      }
    }
  }
}
